import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useTranslation } from "react-i18next";

function BlogSection() {
  const { t } = useTranslation("index-blog-section");

  const query = useStaticQuery(graphql`
    {
      allWpPost {
        totalCount
        nodes {
          title
          id
          slug
          link
          excerpt
          internal {
            description
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  const hasImage = (node) => {
    if (node.featuredImage) {
      return true;
    }
    return false;
  };

  const siteUrl = "https://app-kxp-www-prod-02.azurewebsites.net/";

  const cleanParagraphTag = (text) => {
    const cleanText = text.replace(/<p>/g, "");
    const limitText = cleanText.substring(0, 200);
    return limitText.replace(/<\/p>/g, "");
  };

  const formatData = query.allWpPost.nodes.slice(1, 5);

  return (
    <article
      className="w-full min-h-screen flex flex-row justify-center p-4 py-8 bg-[#faf8f6]"
      id="blog"
    >
      <div className="flex flex-col items-center justify-center w-3/4 h-ull">
        <h2 className="accent-color text-center font-bold text-3xl md:text-4xl">
          {t("title")}
        </h2>
        <div className="flex flex-row flex-wrap gap-4 w-full mt-4">
          {formatData.map((post) => (
            <div
              className="bg-cover bg-center bg-no-repeat max-w-[350px] h-[350px]"
              key={post.id}
              style={
                hasImage(post)
                  ? {
                      backgroundImage: `url(${siteUrl}${post.featuredImage.node.mediaItemUrl})`,
                    }
                  : {}
              }
            >
              <div className="w-full h-full p-4 opacity-0 bg-[#1C2B33ef] transition-all duration-500 hover:opacity-90 leading-6 mb-4 space-y-2">
                <h3 className="accent-color font-bold">{post.title}</h3>
                <p className="white text-base">
                  {cleanParagraphTag(post.excerpt)}
                </p>
                <div className="mt-4">
                  <Link
                    to={`/blog/${post.slug}`}
                    className="border-solid border-2 border-white rounded-sm text-white text-sm font-bold text-center w-1/2 p-2 "
                  >
                    {t("readMore")}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row w-full justify-center pt-16">
          <Link to={`/blog`}>
            <button className="mt-2">{t("button")} </button>
          </Link>
        </div>
      </div>
    </article>
  );
}

export default BlogSection;
