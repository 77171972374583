/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index-home-section.css";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

import Autoplay from "embla-carousel-autoplay";

import KuraFinancialsLogo from "../../img/kura_financials_horizontal_color.png";

function SectionTitle({ text, color }) {
  return (
    <h1 className={`${color} text-4xl md:text-5xl xl:text-6xl font-bold`}>
      {text}
    </h1>
  );
}

function ContentType({ text, color }) {
  return (
    <h2
      className={`text-2xl xl:text-3xl md:text-3xl ${color} mb-4 font-semibold`}
    >
      {text}
    </h2>
  );
}

function SectionSubtitle({ text, color }) {
  return (
    <h2 className={`text-xl xl:text-2xl md:text-2xl p-2 ${color} mb-4`}>
      {text}
    </h2>
  );
}

function AnchorButton({ href, text, target }) {
  return (
    <a href={href} target={target}>
      <button className="p-2 mt-8 text-xl" type="button">
        {text}
      </button>
    </a>
  );
}

function IndexHomeSection() {
  const { t } = useTranslation(["index-home-section"]);

  return (
    <Carousel
      className="h-[calc(100vh-80px)] flex flex-row text-right w-full"
      id="home"
      opts={{
        align: "start",
        loop: true,
      }}
      plugins={[
        Autoplay({
          delay: 3000,
        }),
      ]}
    >
      <CarouselContent>
        <CarouselItem className="carousel-background pt-0 pr-[10vw] pb-32 pl-36 xl:pb-44 xl:pl-48  background_1 justify-start flex  h-[calc(100vh-80px)] w-screen ">
          <div className="flex flex-col h-100 xl:w-3/4 w-4/5 text-start justify-center items-center ">
            <div className="flex flex-col justify-center gap-2">
              <SectionTitle text={t("block01.title")} color="text-white" />
              <SectionSubtitle text={t("block02.text")} color="text-white" />
              <AnchorButton href="#about_us" text={t("block01.button")} />
            </div>
          </div>
        </CarouselItem>
        <CarouselItem className="pl-4 h-[calc(100vh-80px)] w-screen carousel-background background_2 flex flex-col items-center justify-start pt-14">
          <div className="flex flex-row justify-end w-3/4">
            <img
              src={KuraFinancialsLogo}
              alt="Kura Financials Logo"
              className="h-56"
            />
          </div>
          <div className="w-3/4 flex flex-col justify-center text-right">
            <SectionTitle text={t("block02.title")} color="text-teal-700" />
            <SectionSubtitle text={t("block02.text")} color="text-teal-700" />
            <AnchorButton
              href="https://financials.kura.cloud"
              text={t("block02.button")}
              target="_blank"
            />
          </div>
        </CarouselItem>
        <CarouselItem className="background_3 flex flex-col items-end justify-center pl-6 md:pl-12  pr-6 md:pr-12 h-[calc(100vh-80px)] w-screen carousel-background">
          <div className="flex flex-col items-end justify-center p-6 w-full md:w-1/2 bg-opacity-60 bg-zinc-950">
            <ContentType text={t("block03.title")} color="text-teal-300" />
            <SectionTitle text={t("block03.text")} color="text-white" />
            <AnchorButton
              href="https://www.linkedin.com/pulse/12-boas-pr%C3%A1ticas-para-otimiza%C3%A7%C3%A3o-de-custos-na-nuvem-kxp-consulting-lqebf/"
              text={t("block03.button")}
              target="_blank"
            />
          </div>
        </CarouselItem>
      </CarouselContent>
      <div className="absolute bottom-24 md:bottom-16 left-1/2 transform -translate-x-1/2 flex flex-row space-x-6">
        <CarouselPrevious className="text-white h-8 w-8 hover:text-green-400" />
        <CarouselNext className="text-white h-8 w-8 hover:text-green-400" />
      </div>
    </Carousel>
  );
}

export default IndexHomeSection;
