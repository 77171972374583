import React from "react";
import { useTranslation } from "react-i18next";
import "./index-services-section.css";
import { CloudCog, RouterIcon, ServerCogIcon } from "lucide-react";
import { Link } from "gatsby";

function IndexServiceSection() {
  const { t } = useTranslation(["index-services-section"]);
  return (
    <article
      className="bg-right bg-cover bg-no-repeat flex index-services-section justify-center items-center  xl:h-lvh w-full md:p-10"
      id="service"
    >
      <div className="flex flex-col items-center justify-center space-y-4 p-4">
        <h2 className="accent-color text-center font-bold text-3xl md:text-4xl">
          {t("title")}
        </h2>
        <p className="white text-center text-lg md:text-xl">
          {t("description")}
        </p>

        <div className="flex flex-row justify-center flex-wrap gap-4">
          <div className="flex flex-col space-y-2 p-5 bg-zinc-950 max-w-xs mx-auto rounded-sm shadow-xl bg-opacity-70">
            <CloudCog className="h-12 w-10 white " />
            <h3 className="white font-semibold text-lg">
              {t("offer01.title")}
            </h3>
            <p className="white">{t("offer01.description")}</p>
            <ul>
              <li className="white font-medium">{t("offer01.text01")}</li>
              <li className="white font-medium">{t("offer01.text02")}</li>
              <li className="white font-medium">{t("offer01.text03")}</li>
              <li className="white font-medium">{t("offer01.text04")}</li>
            </ul>
          </div>

          <div className="flex flex-col space-y-2 p-5 bg-zinc-950 max-w-xs mx-auto rounded-sm shadow-xl  bg-opacity-70">
            <ServerCogIcon className="h-12 w-10 white" />
            <h3 className="white font-semibold text-lg">
              {t("offer03.title")}
            </h3>
            <p className="white">{t("offer03.description")}</p>
            <ul>
              <li className="white font-medium">{t("offer03.text01")}</li>
              <li className="white font-medium">{t("offer03.text02")}</li>
            </ul>
          </div>

          <div className="flex flex-col space-y-2 p-5 bg-zinc-950 max-w-xs mx-auto rounded-sm shadow-xl  bg-opacity-70">
            <RouterIcon className="h-12 w-10 white" />
            <h3 className="white font-semibold text-lg">
              {t("offer02.title")}
            </h3>
            <p className="white">{t("offer02.description")}</p>
            <ul>
              <li className="white font-medium">{t("offer02.text01")}</li>
              <a
                href="/solutions/apps-modernization"
                className="white font-medium hover:underline "
              >
                {t("offer02.text02")}
              </a>
              <li className="white font-medium">{t("offer02.text03")}</li>
            </ul>
          </div>
        </div>

        <div className="flex flex-row justify-center w-full ">
          <div className="flex flex-col max-w-1/2 text-center  bg-zinc-950 bg-opacity-70 p-6">
            <h3 className="accent-color font-bold text-xl">
              {t("offer04.title")}
            </h3>
            <p className="text-white text-base">{t("offer04.description")}</p>
            <Link to="#contact_us">
              <button className="mt-2">{t("button")}</button>
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}

export default IndexServiceSection;
